import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Accordion from '../../../components/Accordion.js'
import Layout from '../../../components/layout'

const GrihaAll = ({data}) => {
    return <Layout>
        <Helmet>
            <title>Pandit for Griha Pravesh in T Dasarahalli, Bangalore (15+yrs Exp)</title>
            <meta name="description" content="Need Pandit for Griha Pravesh in T Dasarahalli, Bangalore? We provide the Best Pandits for Gruhapravesam in T Dasarahalli, Bangalore, India for top milk boiling or Shubh Griha Pravesh Kalash Pooja for your simple new home opening or apartment entry in Bangalore, India." />
        </Helmet>
        <div id="main" className="pandit-listing">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1 className="desktop">Pandit for Griha Pravesh in T Dasarahalli, <br/>Bangalore (15+yrs Exp)</h1>
                        <h1 className="mobile">Pandit for Griha Pravesh in T Dasarahalli, <br/>Bangalore (15+yrs Exp)</h1>
                    </header>
                    <a href="https://g.co/kgs/MciV3q">
                        <span className="reviews">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <span>Reviews</span>
                        </span>
                    </a>
                    <p>Need Pandit for Griha Pravesh in T Dasarahalli, Bangalore? We provide the Best Pandits for Gruhapravesam in T Dasarahalli, Bangalore, India for top milk boiling or Shubh <a className='no-highlight' href='http://isha.sadhguru.org/blog/yoga-meditation/science-of-yoga/grihapravesham-significance-house-warming/'>Griha Pravesh</a> Kalash Pooja for your simple new home opening or apartment entry in Bangalore, India.</p>
                    <Accordion
          id="1"
          question=" more"
          answer="<h3>Frequently Asked Questions</h3>
<ol>
<li class='bold'>Should the customer arrange for Puja Samagri or would the Panditji arrange?
<span>Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja.</span></li>
<li class='bold'>Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?
<span>No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji.</span></li>
<li class='bold'>Will the Panditji help the customer in choosing good muhurth for ritual?
<span>Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed.</span></li>
<li class='bold'>How much time would it take for the Puja to be completed?
<span>Griha Pravesh Puja would take about 4-6 hours to get completed. We can arrange shorter or longer pujas as per the customer's requirement.</span></li>
<li class='bold'>How can the customers pay? Through cash or Wallet or Netbanking?
<span>Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)</span></li>
</ol>"
        />
                </div>
            </section>
            <section id="two" className="spotlights">
                {data.allFile.nodes.map((value, index) => {
                    return <section>
                        <div className="image">
                            <img src={value.childImageSharp.fluid.src} alt={data.allMarkdownRemark.edges[index].node.frontmatter.title} title={data.allMarkdownRemark.edges[index].node.frontmatter.title}/>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>{data.allMarkdownRemark.edges[index].node.frontmatter.title}</h3>
                                </header>
                                <div className="details">
                                    <span className="label">Years of Experience: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.experience}</span><br/>
                                    <span className="label">Completed his Degree: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.degree}</span><br/>
                                    <span className="label">Well Versed in Puja Traditions as per Regions: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.regions_all}</span><br/>
                                    <span className="label">In Bangalore since: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.bangalore}</span><br/>
                                    <span className="label">Specialized In: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.specialized}</span><br/>
                                    <span className="label">Puja’s Performed: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.pujas}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                })}
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: frontmatter___order})  {
    edges {
      node {
        frontmatter {
          order
          title
          experience
          degree
          regions_all
          bangalore
          specialized
          pujas
        }
      }
    }
  }
  allFile(filter: {name: {in: ["Pandit Ashutosh Pandey", "Pandit Bharat Kumar", "Pandit Hem Chandra Joshi", "Pandit Jayarama Sharma", "Pandit Subodh Pandey", "Pandit TManjunath"]}}, sort: {fields: name}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}`

export default GrihaAll